import React from 'react';
import Header from './components/header/'
import Home from './pages/home/'
import ShowHN from './pages/showhn/'
import BlogBase from "./templates/BlogBase"
import { BlogPage } from "./pages/blog/BlogList"
import AboutUsPage from "./pages/about"
import OurMissionBlog from "./pages/blog/our-mission"
import NotamBlog from "./pages/blog/deciphering-notams-with-soar"
import SMSRuleBlogPost from "./pages/blog/faa-sms-rule"
import './styles/app.scss';


import {
	RouterProvider,
	createBrowserRouter,
    Outlet
} from "react-router-dom";
import { IntercomProvider } from 'react-use-intercom';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
			<div>
				<Header />
				<Home />
			</div>
		),
  },

	{ path: "/about",
		element: (
			<AboutUsPage />
		),
	},

	{ path: "/blog",
		element: (
			<div>
				<Outlet />
			</div>
		),
		children:  [
			{
				path: "/blog/",
				element: (
					<BlogPage />
				)
			},
			{
				path: "/blog/our-mission",
				element: (
					<OurMissionBlog />
				)
			},
			{
				path: "/blog/simplifying-notams-in-aviation",
				element: (
					<NotamBlog />
				)
			},
			{
				path: "/blog/faa-sms-rule",
				element: (
					<SMSRuleBlogPost />
				)
			}
		]
	},
  {
    path: "/showhn",
    element: (
			<div>
				<Header />
				<ShowHN />
			</div>
		),
  },
]);

function App(){
	return (
		<>
		<div id="portal" />
		<IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID ?? ''} autoBoot>
			<RouterProvider router={router} />
		</IntercomProvider>
		</>
	);

}

export default App;
