import React, { Component } from "react";
import './footer.scss';

class Footer extends Component {
  constructor(props) {
      super(props);

  }
  componentDidMount = () => {
  }

  render() {
    return (
      <div className="footer">
        <div className="footer-logo">
          <img src="/images/logo.png" />
        </div>
        <div className="social-row" style={{opacity:0}}>
          <img src="/images/socials.png" />
        </div>
        <div className="footer-menu">
          <div className="menu-item">© 2024 SoarUp, Inc.</div>
        </div>
      </div>
    );
  }
}

export default Footer;
