import React, { Component, ReactNode } from "react";
import './features.scss';
import { Fade as FadeNew } from 'react-awesome-reveal'
import CascadingText from './../cascadingText/';

class Features extends Component {
  constructor(props) {
      super(props);

  }
  componentDidMount = () => {
  }

  render() {

    return (
      <div className="features-section secondary-background">
        <div className="max-width-container">
          <FadeNew duration={750} triggerOnce>
            <div className="gutter section-padding">
              <div className="row">
                <div className="small-12 medium-6">
                  <div className="features-image">
                    <img src="/images/map-20240125.png" />
                  </div>
                </div>
                <div className="small-12 medium-6">
                  <div className="features-text">
                    <div className="features-text-container">
                      <div className="features-title">
                        <CascadingText
                          text="Say goodbye to cumbersome risk assessments"
                          highLightWords={[]}
                        />
                      </div>
                      <div className="features-descripton">
                        <p>
													Explore a new approach to pre-flight risk assessments, prioritizing crucial information tailored to you and your aircraft.
												</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FadeNew>
        </div>
      </div>
    );
  }
}

export function FeatureDisplay({
	imageUrl,
	header,
	description,
	reverse = false,
	addChrome = false,
}: {
	imageUrl: string
	header: string
	description: string | ReactNode
	reverse?: boolean
	addChrome?: boolean
}){
	return (
		<div className="features-section secondary-background">
			<div className="max-width-container">
				<FadeNew duration={750} triggerOnce>
					<div className="gutter">
						<div className={`row row-sm-switch ${reverse ? "reverse" : ""}`}>
							<div className="small-12 medium-6">
								<div className={`features-image ${addChrome ? "feature-image-chrome" : ""}`}>
									<img src={imageUrl} />
								</div>
							</div>
							<div className="small-12 medium-6">
								<div className="features-text">
									<div className="features-text-container">
										<div className="features-title">
											<CascadingText
												text={header}
												highLightWords={[]}
											/>
										</div>
										<div className="features-descripton">
											<p>
												{description}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</FadeNew>
			</div>
		</div>
	);
}

export default Features;
