import React from 'react'
import { Link } from 'react-router-dom';
import BlogBase from "../../templates/BlogBase";

const blogArticles = [
	{
		title: 'Simplifying NOTAMs in Aviation',
		slug: '/blog/simplifying-notams-in-aviation',
		image: '/images/notams-blog-header.png',
	}, 
	{
		title: "FAA's SMS Rule: A Guide for Air Charter Companies",
		slug: '/blog/faa-sms-rule',
		image: '/images/faa-sms-rule-v3.png',
	}, 
]

export function BlogPage(){
	return(
		<BlogBase title="Blog">
			<div className="blog-list">
				{blogArticles.map(blogEntry => {
					return (
						<Link key={blogEntry.slug} to={blogEntry.slug} className="blog-entry">
							<img src={blogEntry.image} />
							<div className="blog-entry-title">
								{blogEntry.title}
							</div>
						</Link>
					)
				})}
			</div>
		</BlogBase>
	)
}
