import React, { ReactNode, useEffect, useMemo, useState } from "react"
import { Testimonial } from "./Testimonial"

export const testimonials = [
	{
		content:(<span>
			SOAR has become integral in my flight planning, especially for filing IFR.
			In other tools, you have to spend a lot of time to get the same information SOAR puts out instantly. 
			The app lists everything I need to be aware of from my departure to destination airport.
			It is a great tool to aid in thorough weather and flight planning; every pilot should have it in their flight planning toolbox.
			</span>),
		author:"Mike C",
		hidden: true,
		// avatar: "https://gravatar.com/avatar/2d773b61a2b160f62815b00e3952c2d8?s=400&d=mp&r=x"
	},
	{
		content: (<span>
		I've been consistently using Safety AI for the past few months. 
		The product has continually improved and has become more reliable, quicker, and easier to use with every update. 
		Soar combines various flight planning aspects, including NOTAMs, TFRs, SIGMETs, and airport weather data into a single platform, enabling us to assess relevant flight risks with a single click. 
			The ability to decode NOTAMs into understandable information adds immense value to this software. 
		I encourage every pilot, whether recreational or professional, to use Safety AI.
		</span>),
		author: "Leagan V",
		hidden: true,
		// avatar: "https://gravatar.com/avatar/2d773b61a2b160f62815b00e3952c2d8?s=400&d=mp&r=x"
	},
	{
		content: (<span>
			I can’t emphasize enough the importance of Soar’s efforts on the SMS and Safety Assurance fronts. {" "}
			<strong>The product that Gary and William are producing is groundbreaking, and OBXA is proud to be partnering with Soar for the development of this solution. </strong>
			The impact on our operations has already been measurable, and the continued development work on the software will no doubt bring more positive impacts for our Air Charter operations.
		</span>),

		author:"Sam Long, Director of Operations at Outer Banks Aviation" 
	}
]

const useScreenWidth = () => {
	const [width, setWidth] = useState(window.innerWidth)
	const onWindowSizeChange = () => {
		setWidth(window.innerWidth)
	}
	useEffect(() => {
		window.addEventListener("resize", onWindowSizeChange)
		return () => {
			window.removeEventListener("resize", onWindowSizeChange)
		}
	}, [])
	const isSmall = width < 800
	const isMedium = width >= 800 && width < 1200
	const isLarge = width >= 1200
	return { width, isSmall, isMedium, isLarge } 
}

export function Testimonials({
	title = "Loved by pilots"
}: {
	title?: string | ReactNode
}){
	const randomizedTestimonials = useMemo(() => {
		return testimonials.filter(testimonial => !testimonial.hidden).sort(() => {
			return 0.5 - Math.random()
		})

	}, [])
	const [currentIndex, setCurrentIndex] = useState(0)
	const onNextClick = () => {
		let nextIndex = currentIndex + 1
		if(nextIndex >= randomizedTestimonials.length){
			nextIndex = 0
		}
		setCurrentIndex(nextIndex)
	}

	const onPreviousClick = () => {
		let nextIndex = currentIndex - 1
		if(nextIndex < 0){
			nextIndex = randomizedTestimonials.length - 1
		}
		setCurrentIndex(nextIndex)
	}

	const orderedTestimonials = useMemo(() => {
		const beginning = randomizedTestimonials.slice(currentIndex)
		const end = currentIndex > 0 ? randomizedTestimonials.slice(0, currentIndex) : []
		return [...beginning, ...end]
		
	}, [currentIndex])

	const { isSmall } = useScreenWidth()
	

	// const testimonial = randomizedTestimonials[currentIndex]
	return (
		<div className="testimonials-section-wrapper">
			<div className="testimonials-section-header">
				{title}
			</div>
			<div className="testimonials-section">
				<button className={["action-icon", isSmall ? "" : "hidden", orderedTestimonials.length <= 1 ? "hidden" : ""].join(" ")} onClick={onPreviousClick}>
					<span className={"material-symbols-outlined"}>
						chevron_left
					</span>
				</button>
				<div className="testimonials-wrapper">
					{ 

						isSmall ? (
							<Testimonial content={orderedTestimonials[0].content} author={orderedTestimonials[0].author} />
						) : 
						orderedTestimonials.slice(0, 2).map((testimonial) => (
							<Testimonial content={testimonial.content} author={testimonial.author} />
						))
					}
				</div>
				<button className={["action-icon", isSmall ? "" : "hidden", orderedTestimonials.length <= 1 ? "hidden" : ""].join(" ")} onClick={onNextClick}>
					<span className={"material-symbols-outlined"}>
						chevron_right
					</span>
				</button>
			</div>
		</div>
	)
}
