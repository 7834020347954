import React, { ReactNode } from 'react'

export function Testimonial({
	content,
	author,
	avatar,
	authorTitle,
}: {
	content: string | ReactNode
	author: string
	avatar?: string
	authorTitle?: string
}){
	return (
		<div className="testimonial">
			<div className="testimonial-content">
				&ldquo;
				{content}
				&rdquo;
			</div>
			<div className="testimonial-author">
				<span> - </span>
				{avatar != null && (
					<img src={avatar} className="testimonial-author-avatar" alt="testimonial author avatar" />
				)}
				<span>
					{author}
				</span>
			</div>
			{authorTitle != null && (
				<div className="testimonial-author-title">
					{authorTitle}
				</div>
			)}
		</div>
	)
}
