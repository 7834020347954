import React, { Component } from "react";
import './hero.scss';
import { Fade as FadeNew } from 'react-awesome-reveal'
import CascadingText from './../cascadingText/';

class Hero extends Component {
  constructor(props) {
      super(props);

  }
  componentDidMount = () => {
  }

  render() {

    return (
      <div className="hero-section black-background">
        <FadeNew duration={750} triggerOnce>
					{this.props.backgroundImage &&
						<div className="hero-background" style={{backgroundImage:'url('+this.props.backgroundImage+')'}}></div>
					}
				</FadeNew>
        <FadeNew duration={750} triggerOnce>
          <div className="hero gutter">
            <div className="hero-content">
              {this.props.icon &&
                <div className="hero-icon hide-for-small">
                  <FadeNew bottom duration={750} distance="50%" triggerOnce>
                    <img src={this.props.icon} />
                  </FadeNew>
                </div>
              }
              <div className="hero-text">
                <CascadingText
                  text={this.props.text}
                  highLightWords={this.props.highLightWords}
                />
              </div>
              {this.props.description &&
                <div className="hero-description">
                  <FadeNew bottom duration={750} delay={400} distance="50%" triggerOnce>
                    {this.props.description}
                  </FadeNew>
                </div>
              }
              {(this.props.buttonText || this.props.buttonSubtext) &&
                <div className="hero-cta-container">
                  <FadeNew bottom duration={750} delay={400} distance="50%" triggerOnce>
                    <div>
                      {this.props.buttonText &&
                          <button className="hero-button button primary-gradient" onClick={this.props.onButtonClick ?? (() => {})}>
                            {this.props.buttonText}
                          </button>
                      }
                      {this.props.buttonSubtext &&
                        <div className="hero-button-subtext">{this.props.buttonSubtext}</div>
                      }
                    </div>
                  </FadeNew>
                </div>
              }
              </div>

            </div>
        </FadeNew>
      </div>
    );
  }
}

export default Hero;
