import React from 'react'
import BlogBase from "../../templates/BlogBase";

export default function NotamBlog(){
	return (
		<BlogBase title="Simplifying NOTAMs in Aviation">
			<div>

				<p>
					In the bustling world of aviation, the exchange of critical information is paramount to ensuring safe and efficient flights. However, navigating through the dense labyrinth of Notices to Air Mission (NOTAMs) has long been a daunting task for pilots and aviation professionals alike. In this blog post, we delve into the challenges posed by NOTAMs and explore how innovative solutions, such as those offered by Soar, are changing the way we decode and utilize this essential information.
				</p>

				<h2>A Brief History of NOTAMs</h2>

				<p>
					The history of NOTAMs dates back to the early days of aviation when pilots and air traffic controllers needed a way to communicate important information quickly and effectively. Originally, NOTAMs were distributed via telegraph and radio broadcasts. Over time, technological advancements have streamlined the dissemination process, but the challenges of deciphering and prioritizing NOTAMs persist to this day.
				</p>

				<h2>The Challenges of NOTAMs Today</h2>

				<blockquote>
					<p>
						Just pages and pages and pages of irrelevant material, the important stuff gets buried.
					</p>
				</blockquote>

				<p>
					NOTAMs serve as vital notifications, alerting pilots and aviation personnel to potential hazards or changes along their flight routes. Despite their importance, deciphering NOTAMs remains a formidable challenge for several reasons:
				</p>


				<p>
					<strong>Complexity and Readability:</strong> Traditional NOTAMs often resemble cryptic puzzles, filled with abbreviations, codes, and technical jargon. The dense text can be overwhelming and time-consuming to decipher, especially amidst the pressure of pre-flight preparations.
				</p>


				<p>
					<strong>Relevance and Filtering:</strong> Another significant hurdle lies in identifying which NOTAMs are pertinent to a particular flight. With a vast array of notifications covering diverse geographical areas and operational aspects, pilots may struggle to sift through the clutter and pinpoint the information relevant to their journey.
				</p>
				<blockquote hidden>
					<p>
					Just pages and pages and pages of irrelevant material, the important stuff gets buried.
					</p>
				</blockquote>

				<p>
					<strong>Risk of Oversight:</strong> In the face of these challenges, pilots may inadvertently overlook crucial NOTAMs, potentially jeopardizing the safety and efficiency of their flights. The consequences of misinterpreting or neglecting critical information can be severe, underscoring the urgent need for a more streamlined approach to NOTAM management.
				</p>

				<blockquote hidden>
					<p>
						If you were to read them, every one of them - you would spend at least 20 minutes going through the NOTAMs.
					</p>
				</blockquote>

				<h2>
					Expert Insights on NOTAMs
				</h2>

				According to expert testimony where the NOTAM system was identified as a contributing factor in an NTSB hearing after Air Canada Flight 759 attempted to land on a taxiway at SFO:

				<ul>
					<li>
						"That’s what NOTAMs are, they’re just a pile of garbage that no one pays any attention to."
					</li>

					<li>
						"The NOTAM System is really messed up, I can tell you that right now.”
					</li>

					<li>
						“If you were to read them, every one of them - you would spend at least 20 minutes going through the NOTAMs.”
					</li>

					<li>
						“They’re written in some kind of language that only a computer programmer would really understand.”
					</li>

					<li>
						“Just pages and pages and pages of irrelevant material, the important stuff gets buried."
					</li>
				</ul>

				<p>
				These statements illustrate the frustration and inefficiencies inherent in the current NOTAM system. Pilots and aviation professionals face significant obstacles in navigating this crucial information, highlighting the urgent need for innovative solutions like those offered by Soar.
				</p>

				<h2>The Solution: NOTAMs that Pilots Can Understand</h2>


				<p>
					Founded by Gary Tougas and William Zhang, Soar is dedicated to redefining aviation safety by revolutionizing information exchange and accessibility. While NOTAMs represent a pivotal component of their broader mission, Soar's vision extends far beyond, embracing a holistic approach to enhancing aviation safety.
				</p>

				<figure>
					<picture style={{minHeight: "100px"}}>
						<source srcSet="/images/notams-blog-example.png" media="(min-width: 800px)" />
						<img src="/images/notams-blog-example-mobile.png" alt="example Soar translated notam"/>
					</picture>
					<figcaption>
						Translation for:
						{" "}
						<pre>IAP SAN CARLOS, SAN CARLOS, CA. RNAV (GPS) Y RWY 30, ORIG-B... CIRCLING RWY 12 NA AT NIGHT. 2304131237-2504131800EST</pre>
					</figcaption>
				</figure>

				<p>
					Soar's innovative approach addresses the inherent challenges of traditional NOTAMs by:
				</p>

				<p>
					<strong>Human-Readable Format:</strong> By harnessing the power of Large Language Model technology (LLMs), Soar transforms convoluted NOTAMs into clear, concise, and easily understandable language. Gone are the days of deciphering cryptic codes; pilots can now access critical information in a format that resonates intuitively.
				</p>

				<p>
					<strong>Contextual Relevance:</strong> Soar's platform goes beyond mere translation, taking into account the unique capabilities of each aircraft and the operational context of each flight. By tailoring NOTAMs to specific aircraft profiles, as well as factors such as day-night operations and IFR vs. VFR flights, Soar ensures that pilots receive only the most pertinent and actionable information.
				</p>

				<p>
					<strong>Enhanced Accessibility:</strong> With Soar's user-friendly interface, pilots can seamlessly navigate through NOTAMs, effortlessly filtering and prioritizing notifications based on their relevance to upcoming flights. This streamlined approach minimizes the risk of oversight and empowers pilots to make informed decisions with confidence.
				</p>

				<h2>
					Join Us in Making NOTAMs Better
				</h2>

				<p>
					You can check out our NOTAM experience by hitting the button below. If you have ideas for how we can make NOTAMs better for pilots, please email us at garyt@soarup.app and williamgkzhang@soarup.app. Your feedback is invaluable as we strive to improve aviation safety.  
				</p>


			</div>
		</BlogBase>
	)
}
