import React from 'react'
import { ReactNode } from "react";
import { Outlet } from 'react-router-dom';
import Header from '../components/header'
import Bumper from '../components/bumper'
import "./BlogBase.scss"
import { getAppUrl } from '../utils/ops';
import { useIntercom } from 'react-use-intercom';

export default function BlogBase({
	children,
	title = ""
}: {
	title: string
	children: ReactNode
}){
	const {show, showNewMessage} = useIntercom()

	return (
		<div className="blog-base-wrapper">
			<Header />
			<div className="blog-base">
				<div className="blog-base-header max-width-wrapper">
					<h1>
						{title}
					</h1>
				</div>
				<div className="blog-base-body max-width-wrapper">
					{children}
				</div>
			</div>
			<Bumper
				icon={"/images/glyph-soar-white.svg"}
				title="Discover a new approach to safety management"
				//highLightWords={['Be','a','Part','of','It']}
				//descripton={`Secure your spot for a first-hand experience with next-gen web automation. Don't get left behind.`}
				//
				buttonText="Contact us"
				buttonLink={getAppUrl("/register")}
				onButtonClick={() => {showNewMessage("I'd like to learn more about Soar.")}}
			/>
		</div>
	)

}
