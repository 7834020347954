import React from "react"
import BlogBase from "../../templates/BlogBase"
import "./aboutus.scss"

export default function AboutUsPage(){
	return (
		<BlogBase title="About Soar">
			<div className="about-us-page">
				<div className="about-us-content">
					<p>
						Soar was founded by William Zhang, a software engineer, and Gary Tougas, a trained pilot and product manager. Our journey began with a simple idea: to make flight scheduling easier in the aviation industry. However, as we delved deeper into the challenge and listened to our early users during the beta phase, we realized that there was a more pressing need: flight safety.
					</p>

					<p>
						We listened, learned, and pivoted. Now, our focus is on creating tools that prioritize safety before takeoff. Think of it as a safety toolkit designed specifically for pilots who either own their own aircraft or rent one from others.
					</p>

					<p>
						As we continued to immerse ourselves in the aviation community, we discovered a significant update from the FAA regarding safety requirements for part 135 operators. We saw an opportunity to make a difference here too, so we partnered with part 135 operators to develop solutions tailored to their needs – coming soon.
					</p>

					<p>
						At Soar, our goal is simple: to keep pilots, crew members, and the companies they work for safe – from the moment they plan their flight to the moment they land. We're dedicated to making aviation safer and more accessible for everyone.
					</p>
				</div>
				<div className="about-us-figure">
					<figure>
						<img src="/images/soar-founders.jpg" />
						<figcaption>William Zhang and Gary Tougas, founders of Soar</figcaption>
					</figure>
				</div>
			</div>

		</BlogBase>
	)
}
