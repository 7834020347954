import React from 'react'
import BlogBase from "../../templates/BlogBase";

export default function OurMissionBlog(){
	return (
		<BlogBase title="Our Mission">
			<div>

				<h2 id="the-problem">The Problem</h2>
				<p>What we&#39;ve heard from a lot of pilots is that there is a lot of safety information out there but it&#39;s cumbersome to sift through it all and figure out what is actually relevant.   Furthermore, some data such as NOTAMs and PIREPs are harder to read since they are encoded in a shorthand manner made to be read by both computer systems and pilots.  </p>
				<p>So what ends up happening is that some pilots downright skip the pre-flight safety brief or they only look at certain pieces of information.  This is more prevalent in pilots that only fly in one area and they are used to the conditions and threats around them.  The problem is that conditions are ever changing and sometimes a TFR pops up that that pilot may not be aware of.</p>
				<p>The FAA and Aviation Weather do have good free tools that help disseminate the relevant information but usually a pilot has to go through 2-5 pages just to get that information.  There are paid solutions that colocate all the relevant information but it&#39;s not prioritized so pilots have to go through different sections of these apps to still figure out what is relevant to them.</p>
				<p>All in all, getting a pre-flight safety assessment is quite a chore, that, though it isn&#39;t safe, is sometimes skipped. (That&#39;s not to say that all pilots are like this.  Some do a full safety briefing every single time before they fly.)</p>
				<h2 id="our-solution">Our Solution</h2>
				<p>What we&#39;ve built makes it easier to get some vital pieces of information to a pilot and prioritizes it based on threats.  We&#39;re currently tailoring it towards GA pilots and the types of planes usually flown in GA though that may change in the future.  </p>
				<p>With items like NOTAMs and PIREPs, we&#39;re using a LLM to translate those pieces of information into something more easily human readable while still allowing a toggle to read the raw version.  With METARs and TAFs (weather information), we&#39;re plucking out information and displaying them individually and additionally adding some additional weather data not normally presented in METARs/TAFs.  
				</p>
				<p>With our threat prioritization, we&#39;re bubbling the more dangerous ones to the top so they are more easily noticable.  Our threat prioritization is as follows:</p>
				<ul>
					<li><strong>Red:</strong>  risks that may put your life in danger or have a legal risk</li>
					<li><strong>Orange:</strong> risks that may be dangerous in certain circumstances</li>
					<li><strong>Yellow:</strong> risks that may inconvenience you</li>
				</ul>
				<p>Additionally we are determining risk based on factors of the flight.  It doesn&#39;t make sense to flag a risk of a runway light outage at your departure airport especially if you are taking off during the day.  Similarly, a TFR is not as risky if you are filing IFR as ATC would most likely route you around it.  </p>
				<p>We&#39;re hoping that with a bit of UI and some work to prioritize these risks, it becomes easier for pilots to do a quick pre-flight safety assessment, and flying becomes safer.</p>
				<div style={{display: "none"}}>
					<h2 id="demo">Demo</h2>
					<p>You can register with the link above and get the product free for two weeks.  However we know that HN hates login walls so you can access a demo of a result set here: </p>
					<div className="button-wrapper">
							<a className="button primary-gradient" href="https://ops.soarup.app/demo">Demo Here!</a>
					</div>
					<p>The demo is comprised of data that is usually sent down from our server and just hardcoded into the demo.  This was a safety brief conducted on Jan 30 from KILN to KVES with a takeoff time of 4PM at the time zone of the departure and an airplane with a true airspeed of 120 kts.  </p>
				</div>
				<h2 id="what-s-next">What&#39;s next</h2>
				<p>We definitely have more work to do and we&#39;re welcome to any and all feedback.  Just hit the intercom/help button and let us know what you&#39;d like to see.  </p>
				<p>What we know we want to do is:</p>
				<ul>
					<li>Add route customization that includes navaids/navfixes instead of just a direct flight</li>
					<li>Add more risks into the system</li>
					<li>Add more sophisticated airplane performance metrics that affect risks</li>
					<li>Add pilot experience into our risk determination</li>
					<li>Potentially spin this off into a safety management system for organizations.  </li>
				</ul>
				<h2 id="thanks-for-reading-">Thanks for reading!</h2>
				<p>If you&#39;ve made it this far, thanks for reading.  If you would like to contact us directly, you can find us at williamgkzhang[AT]soarup.app and garyt[AT]soarup.app</p>
			</div>

		</BlogBase>
	)
}
