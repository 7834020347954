import React, { Component } from "react";
import { Fade as FadeNew } from 'react-awesome-reveal'
import './cascadingText.scss';

class CascadingText extends Component {
  constructor(props) {
      super(props);

  }
  componentDidMount = () => {
  }

  render() {
    let text = this.props.text;
    if(text){
      text = text.split(' ');
    }
    return (
      <div className="cascading-text-container">
      {text && text.map((word,index) =>{
        let wordSpan = <div className="cascading-text">{word+' '}</div>
        if(this.props.highLightWords){
          if(this.props.highLightWords.indexOf(word) != -1){
            wordSpan = <div className="cascading-text gradient-text">{word+' '}</div>
          }
        }
        return (
					<FadeNew bottom duration={500} delay={index*80} distance="20%" triggerOnce>
						<span>
							{wordSpan}&nbsp;
						</span>
					</FadeNew>
				)
      })}
      </div>
    );
  }
}

export default CascadingText;
