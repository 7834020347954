import React from 'react'
import './header.scss';
import {Link} from "react-router-dom";
import { getAppUrl } from '../../utils/ops';
import * as Dialog from '@radix-ui/react-dialog';
import { useIntercom } from 'react-use-intercom';

function MobileMenu(){
	return (
		<Dialog.Root>
			<Dialog.Trigger className="show-small menu-item hamburger-menu-button">
				<span className="material-symbols-outlined">
					menu
				</span>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className="dropdown-overlay"/>
				<Dialog.Content className="dropdown-content">
					<div className="dropdown-header">
						<div className="header-logo">
							<img src="/images/logo.png" />
						</div>
						<Dialog.Close asChild>
							<button className="dropdown-close-button">
								<span className="material-symbols-outlined">
									close
								</span>
							</button>
						</Dialog.Close>
					</div>
					<div className="dropdown-menu">
						<Link to="/about" className="dropdown-menu-item">
							About
						</Link>
						<Link to="/blog" className="dropdown-menu-item">
							Blog
						</Link>

						<a href={getAppUrl('/')} className="dropdown-menu-item">
							Login
						</a>
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}

function Header({
}: {
}){
	const { showNewMessage } = useIntercom()
	const onButtonClick = () => {
		showNewMessage("I'd like to learn more about Soar.")
	}

	return (
		<div className="header">
			<div className="header-logo">
				<Link to="/">
					<img src="/images/logo.png" />
				</Link>
			</div>
			<div className="header-menu-center hide-for-small">
				<Link to="/about">
					<div className="menu-item">  
						About	
					</div>
				</Link>
				<Link to="/blog">
					<div className="menu-item">  
						Blog
					</div>
				</Link>
			</div>
			<div className="header-menu-right">
				<a className="hide-for-small" href={getAppUrl('/')}>
					<div className="menu-item">Login</div>
				</a>
				<MobileMenu />
				<button className="menu-item button primary-gradient" onClick={onButtonClick}>Contact us</button>
			</div>
		</div>
	);
}

export default Header;
