import React, { Component } from "react";
import './bumper.scss';
import { Fade as FadeNew } from 'react-awesome-reveal'
import CascadingText from './../cascadingText/';

function Bumper({
	icon,
	gradientTitle,
	title,
	highLightWords,
	description,
	buttonText,
	buttonLink,
	backgroundImage,
	onButtonClick
}) {

	return (
		<div className="bumper-section">
			<FadeNew duration={750} triggerOnce>
				<div className="bumper">
					<div className="bumper-content">
						<div className="bumper-text">
							{icon &&
								<div className="bumper-icon">
									<FadeNew bottom duration={750} distance="50%" triggerOnce>
										<img src={icon} />
									</FadeNew>
								</div>
							}
							{gradientTitle && <h3 className="gradient-text">{gradientTitle}</h3>}
							{title &&
								<div className="bumper-title">
									<CascadingText
										text={title}
										highLightWords={highLightWords}
									/>
								</div>
							}
							{description &&
								<div className="bumper-description">
									{description}
								</div>
							}
						</div>
						<FadeNew bottom duration={750} delay={400} distance="50%" triggerOnce>
							<div>
								{buttonText &&
										<button className="button primary-gradient" onClick={onButtonClick ?? (() => {})}>
											<div className="animation-text-gradient">
												{buttonText}
											</div>
										</button>
								}
							</div>
						</FadeNew>
					</div>
					{backgroundImage &&
						<div className="bumper-background" style={{backgroundImage:'url('+backgroundImage+')'}}></div>
					}
				</div>
			</FadeNew>
		</div>
	);
}

export default Bumper;
